import React from "react"
import Helmet from "react-helmet"
import { Layout } from "../layouts"
import { Flex, Link, Button, useDisclosure } from "@chakra-ui/react"
import { PressModal as BasePressModal } from "../components/press"
import { ContactModal } from "../components/contact"
import XMASBackgroundImg from "../images/until-next-xmas.png"
import tinyXMASImg from "../images/until-next-christmas-tiny.png"
import XMASCoverImg from "../images/until-next-christmas.png"
import smallXMASCoverImg from "../images/until-next-christmas-small.png"
import pressImg from "../images/press.jpg"
import smallPressImg from "../images/press-small.jpg"

const CustomButton = ({ children, ...rest }) => {
    return (
        <Button
            variant="ghost"
            fontSize="2xl"
            color="white"
            fontWeight="bold"
            _hover={{ color: "#FFE5B4" }}
            {...rest}
        >
            {children}
        </Button>
    )
}

const PressModal = ({ isOpen, onClose }) => {
    const title = "UNTIL NEXT CHRISTMAS"
    const texts = {
        SV: `Efter att ha medverkat i två Grammis-nominerade R&B-projekt i rad, debuterat med EP:n "sad awkward black girl" samt Jireel-samarbetet "Tell Your Friends", är R&B-singer/songwritern Naomi Vaughn utan tvekan en artist att hålla ögonen på. Med sin distinkta penna målar hon berättelser om ämnen som nostalgi, avund, återupprättelse och depression, alltid med en kombination av emotionellt djup och en skarp humoristisk ton.`,
        EN: `After contributing to two consecutive Grammis-nominated R&B projects, her debut EP "sad awkward black girl" and Jireel feature ”Tell Your Friends”, R&B singer-songwriter Naomi Vaughn is undoubtedly an artist to watch. With her distinctive pen, she crafts vivid narratives of subjects like nostalgia, envy, betrayal, retaliation, and depression, all laced with both emotional depth and a sharp comedic edge.`,
    }
    return (
        <BasePressModal
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            texts={texts}
            fallbackPressImage={smallPressImg}
            pressImage={pressImg}
            fallBackAlbumCover={smallXMASCoverImg}
            albumCover={XMASCoverImg}
        />
    )
}

const IndexPage = () => {
    const {
        isOpen: isPressModalOpen,
        onOpen: onPressModalOpen,
        onClose: onPressModalClose,
    } = useDisclosure()
    const {
        isOpen: isContactModalOpen,
        onOpen: onContactModalOpen,
        onClose: onContactModalClose,
    } = useDisclosure()

    return (
        <Layout>
            <Helmet
                title="NAOMI VAUGHN | UNTIL NEXT CHRISTMAS"
                link={[
                    {
                        rel: "icon",
                        type: "image/png",
                        href: tinyXMASImg,
                        sizes: "16x16",
                    },
                ]}
            />
            <Flex
                position="absolute"
                backgroundImage={XMASBackgroundImg}
                backgroundPosition="center"
                backgroundSize="cover"
                height="100dvh"
                width="100dvw"
                fontFamily="Arimo"
            >
                <Flex
                    width={{
                        base: "container.xs",
                        sm: "container.sm",
                        md: "container.md",
                    }}
                    mt="auto"
                    mx="auto"
                    mb="4rem"
                    justifyContent="space-between"
                    backgroundColor="rgba(7,86,0,0.4)"
                >
                    <CustomButton onClick={onPressModalOpen}>
                        PRESS
                    </CustomButton>
                    <CustomButton
                        as={Link}
                        href="https://open.spotify.com/track/5nbUHwd1fIwhjJN4f0xEsV?si=11hQBfLqT4-pm07g2KWYDg"
                        target="_blank"
                    >
                        LISTEN
                    </CustomButton>
                    <CustomButton onClick={onContactModalOpen}>
                        CONTACT
                    </CustomButton>
                </Flex>
                <PressModal
                    isOpen={isPressModalOpen}
                    onClose={onPressModalClose}
                />
                <ContactModal
                    isOpen={isContactModalOpen}
                    onClose={onContactModalClose}
                />
            </Flex>
        </Layout>
    )
}

export default IndexPage
