import React from "react"
import Helmet from "react-helmet"
import { Layout } from "../../layouts"
import {
    Flex,
    Link,
    Button,
    useDisclosure,
    Image,
    keyframes,
    Box,
} from "@chakra-ui/react"
import { PressModal as BasePressModal } from "../../components/press"
import { ContactModal } from "../../components/contact"
import pressImg from "../../images/press.jpg"
import smallPressImg from "../../images/press-small.jpg"
import callMyMamaImg from "../../images/call-my-mama.png"
import smallCallMyMamaImg from "../../images/call-my-mama-small.png"
import tinyCallMyMamaImg from "../../images/call-my-mama-tiny.png"
import callMyMamaCoverImg from "../../images/call-my-mama-cover.png"
import CONTACT from "../../images/CONTACT.png"
import PRESS from "../../images/PRESS.png"
import LISTEN from "../../images/LISTEN.png"

const PressModal = ({ isOpen, onClose }) => {
    const title = "CALL MY MAMA"
    const texts = {
        SV: `Föreställ dig att du är 10 år gammal. Du ramlar med cykeln och skrapar knäet. Du springer hem till din mamma, hon tvättar såret, plåstrar om dig och ger dig en puss. Så, nu är allt bättre. När vi växer upp måste vi lära oss att plåstra om oss själva när vi snubblar genom livet. "Call My Mama" är som att springa hem som det där lilla barnet. Den fängslande produktionen av Wahib och S.T.Y.A känns som en varm, tröstande kram och tar dig tillbaka till en nostalgisk Motown-glittervärld blandat med 00-talets chipmunk soul Hiphop. Oavsett om du har en mamma, en morfar eller en vän behöver vi alla någon som kan sätta plåster på våra små sår, och "Call My Mama" finns här för att hjälpa dig med det.\n\nEfter att ha lånat ut sin penna till Grammis-nominerade R&B-projekt två år i rad, debuterat med EP:n "sad awkward black girl" samt Jireel-samarbetet "Tell Your Friends", är R&B-singer/songwritern Naomi Vaughn utan tvekan en artist att hålla ögonen på. Med sitt distinkta historieberättande målar hon nostalgiskt om ämnen som avund, återupprättelse och depression, alltid med en kombination av emotionellt djup och en skarp humoristisk ton.`,
        EN: `Imagine you’re 10 years old. You fall on your bike and scrape your knee. You run home to your mama, she washes it, patches you up and gives you a kiss. There, it’s all better. As we grow up we have to learn to patch ourselves up as we stumble through life. “Call My Mama” is running home like that little kid. The captivating production by Wahib and S.T.Y.A feels like a warm, comforting hug and takes you back to nostalgic Motown glitter blended with 2000s chipmunk soul hip-hop. Whether you have a mom, a grandfather or a friend we all need someone to put bandaids on our boo boo’s and “Call My Mama” is here to help you with that.\n\nAfter lending her pen to Grammis-nominated R&B projects two years in a row, debuting with the EP "sad awkward black girl" and the Jireel collaboration "Tell Your Friends”, R&B singer-songwriter Naomi Vaughn is undoubtedly an artist to watch. With her distinctive storytelling, she paints nostalgically on themes like envy, redemption, and depression — always with a combination of emotional depth and a sharp, witty tone.`,
    }
    return (
        <BasePressModal
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            texts={texts}
            fallBackAlbumCover={smallCallMyMamaImg}
            albumCover={callMyMamaCoverImg}
            fallbackPressImage={smallPressImg}
            pressImage={pressImg}
        />
    )
}

const IndexPage = () => {
    const {
        isOpen: isPressModalOpen,
        onOpen: onPressModalOpen,
        onClose: onPressModalClose,
    } = useDisclosure()
    const {
        isOpen: isContactModalOpen,
        onOpen: onContactModalOpen,
        onClose: onContactModalClose,
    } = useDisclosure()

    return (
        <Layout>
            <Helmet
                title="NAOMI VAUGHN | CALL MY MAMA"
                link={[
                    {
                        rel: "icon",
                        type: "image/png",
                        href: tinyCallMyMamaImg,
                        sizes: "16x16",
                    },
                ]}
            />

            <Flex
                position="absolute"
                backgroundImage={callMyMamaImg}
                backgroundPosition="center"
                backgroundSize="cover"
                height="100dvh"
                width="100dvw"
                fontFamily="Arimo"
            >
                <Flex
                    width={{
                        base: "container.xs",
                        sm: "container.sm",
                        md: "container.md",
                    }}
                    mt="auto"
                    mx="auto"
                    mb="4rem"
                    justifyContent="space-between"
                >
                    <Image
                        maxW="30%"
                        src={PRESS}
                        onClick={onPressModalOpen}
                        cursor="pointer"
                    />
                    <Link
                        href="https://on.soundcloud.com/XyRYXPgQq8MDntjW9"
                        target="_blank"
                        maxW="30%"
                    >
                        <Image src={LISTEN} />
                    </Link>
                    <Image
                        maxW="30%"
                        src={CONTACT}
                        onClick={onContactModalOpen}
                        cursor="pointer"
                    />
                </Flex>
                <PressModal
                    isOpen={isPressModalOpen}
                    onClose={onPressModalClose}
                />
                <ContactModal
                    isOpen={isContactModalOpen}
                    onClose={onContactModalClose}
                />
            </Flex>
        </Layout>
    )
}

export default IndexPage
