import React from "react"
import Helmet from "react-helmet"
import { Layout } from "../../layouts"
import peachBackgroundImg from "../../images/peachbackground.png"
import { Flex, Link, Button, useDisclosure } from "@chakra-ui/react"
import { PressModal as BasePressModal } from "../../components/press"
import { ContactModal } from "../../components/contact"
import peach from "../../images/peach.png"
import pressImg from "../../images/press.jpg"
import smallPressImg from "../../images/press-small.jpg"
import juicyCoverImg from "../../images/juicycover.png"
import smallJuicyCoverImg from "../../images/juicycover-small.jpg"

const CustomButton = ({ children, ...rest }) => {
    return (
        <Button
            variant="ghost"
            fontSize="2xl"
            color="white"
            _hover={{ color: "#FFE5B4" }}
            {...rest}
        >
            {children}
        </Button>
    )
}

const PressModal = ({ isOpen, onClose }) => {
    const title = "JUICY"
    const texts = {
        SV: `Efter att ha medverkat i två Grammis-nominerade R&B-projekt i rad, debuterat med EP:n "sad awkward black girl" samt Jireel-samarbetet "Tell Your Friends", är R&B-singer/songwritern Naomi Vaughn utan tvekan en artist att hålla ögonen på. Med sin distinkta penna målar hon berättelser om ämnen som nostalgi, avund, återupprättelse och depression, alltid med en kombination av emotionellt djup och en skarp humoristisk ton.`,
        EN: `After contributing to two consecutive Grammis-nominated R&B projects, her debut EP "sad awkward black girl" and Jireel feature ”Tell Your Friends”, R&B singer-songwriter Naomi Vaughn is undoubtedly an artist to watch. With her distinctive pen, she crafts vivid narratives of subjects like nostalgia, envy, betrayal, retaliation, and depression, all laced with both emotional depth and a sharp comedic edge.`,
    }
    return (
        <BasePressModal
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            texts={texts}
            fallBackAlbumCover={smallJuicyCoverImg}
            albumCover={juicyCoverImg}
            fallbackPressImage={smallPressImg}
            pressImage={pressImg}
        />
    )
}

const IndexPage = () => {
    const {
        isOpen: isPressModalOpen,
        onOpen: onPressModalOpen,
        onClose: onPressModalClose,
    } = useDisclosure()
    const {
        isOpen: isContactModalOpen,
        onOpen: onContactModalOpen,
        onClose: onContactModalClose,
    } = useDisclosure()

    return (
        <Layout>
            <Helmet
                title="NAOMI VAUGHN | JUICY"
                link={[
                    {
                        rel: "icon",
                        type: "image/png",
                        href: peach,
                        sizes: "16x16",
                    },
                ]}
            />
            <Flex
                position="absolute"
                backgroundImage={peachBackgroundImg}
                backgroundPosition="center"
                backgroundSize="cover"
                height="100dvh"
                width="100dvw"
                fontFamily="Arimo"
            >
                <Flex
                    width={{
                        base: "container.xs",
                        sm: "container.sm",
                        md: "container.md",
                    }}
                    mt="auto"
                    mx="auto"
                    mb="4rem"
                    justifyContent="space-between"
                >
                    <CustomButton onClick={onPressModalOpen}>
                        PRESS
                    </CustomButton>
                    <CustomButton
                        as={Link}
                        href="https://open.spotify.com/track/0IvmW2HJrScnzsMIMlSq7G?si=5b49e7e247324d07"
                        target="_blank"
                    >
                        LISTEN
                    </CustomButton>
                    <CustomButton onClick={onContactModalOpen}>
                        CONTACT
                    </CustomButton>
                </Flex>
                <PressModal
                    isOpen={isPressModalOpen}
                    onClose={onPressModalClose}
                />
                <ContactModal
                    isOpen={isContactModalOpen}
                    onClose={onContactModalClose}
                />
            </Flex>
            {/* <Peach /> */}
        </Layout>
    )
}

export default IndexPage
